import { TRateType } from "../types";
import { TBillInfo } from "../types/bill-book";
import { AddBillLotInfo } from "../types/smartchart/addBill";
import { OnArriavlSubLot } from "../types/smartchart/onArrival";


export function isLotAuctioned(lot: OnArriavlSubLot) {
  let value = false;
  if (lot.rateType == 'RATE_PER_KG') {
    value = lot.soldQty > 0;
  } else {
    value = lot.soldWeight > 0;
  }
  return value;
}

export function isRatePerQuantity(rateType: string) {
  let value = false;
  if (rateType == 'RATE_PER_UNIT') {
    value = true;
  }
  return value;
}

export function isQtyTypeUnit(qtyUnit: string) {
  let value = false;
  switch (qtyUnit) {
    case 'BAGS':
    case 'SACS':
    case 'CRATES':
    case 'BOXES':
      value = true;
      break;
    default:
      break;
  }
  return value;
}

export function getGroupedBags(bagsList: Array<{
  id: number;
  total: number;
  wastage: number;
  weight: number;
  netWeight: number;
  qty: number,
  rate: number,
}> | null) {
  // Sort bags by rate
  const sortedBags = [...bagsList!].sort((a, b) => a.rate - b.rate);
  const bagsGroupList = [];
  let bagIndex = 0;

  for (let index = 0; index < sortedBags.length; index++) {
    const isRateChanged = index > 0 && sortedBags[index - 1].rate !== sortedBags[index].rate;

    if (isRateChanged) {
      const subBagsList = sortedBags.slice(bagIndex, index);
      let groupQuantity = 0;
      let groupTotalWeight = 0;
      let groupWastage = 0;
      let groupNetWeight = 0;
      let groupAvgRate = 0;
      let groupTotal = 0;

      subBagsList.forEach(bag => {
        groupQuantity += bag.qty;
        groupTotalWeight += bag.weight;
        groupWastage += bag.wastage;
        groupNetWeight += bag.netWeight;
        groupTotal += bag.total;
        groupAvgRate = bag.rate; // Assuming all bags in the group have the same rate
      });

      bagsGroupList.push({ groupQuantity, groupTotalWeight, groupWastage, groupNetWeight, groupAvgRate, groupTotal, subBagsList });
      bagIndex = index;
    }
  }

  // Handle last group
  if (bagIndex < sortedBags.length) {
    const subBagsList = sortedBags.slice(bagIndex);
    let groupQuantity = 0;
    let groupTotalWeight = 0;
    let groupWastage = 0;
    let groupNetWeight = 0;
    let groupAvgRate = 0;
    let groupTotal = 0;

    subBagsList.forEach(bag => {
      groupQuantity += bag.qty;
      groupTotalWeight += bag.weight;
      groupWastage += bag.wastage;
      groupNetWeight += bag.netWeight;
      groupTotal += bag.total;
      groupAvgRate = bag.rate; // Assuming all bags in the group have the same rate
    });

    bagsGroupList.push({ groupQuantity, groupTotalWeight, groupWastage, groupNetWeight, groupAvgRate, groupTotal, subBagsList });
  }

  return bagsGroupList;
};

export function getAvailableQtyWeights(lotsList: AddBillLotInfo[], selectedLot: AddBillLotInfo) {
  // Find the main lot (where lotId and id match and isMainLot is true)
  const mainLotIndex = lotsList.findIndex(
    (lot) =>
      lot.isMainLot &&
      lot.qrCode === selectedLot.qrCode
  );


  // If no main lot found, return the original list
  if (mainLotIndex < 0) {
    return lotsList;
  }

  const mainLotInfo = lotsList[mainLotIndex];
  let avlQty = mainLotInfo.avlQty;
  let avlWeight = mainLotInfo.avlWeight;
  let avlWastage = mainLotInfo.avlWastage;

  // Iterate through the list and adjust the available quantity, weight, and wastage
  for (let i = 0; i < lotsList.length; i++) {
    const lot = lotsList[i];
    if (!lot.isMainLot && lot.qrCode == selectedLot.qrCode) {
      avlQty -= Number(lotsList[i - 1].qty); // Assuming "i - 1" is the lot to subtract
      avlWeight -= Number(lotsList[i - 1].weight);
      avlWastage -= Number(lotsList[i - 1].wastage);

      // Merge the adjusted values back into the lot (assuming `merge` is a function you need to implement or replace)
      lotsList[i] = {
        ...lot,
        avlQty: avlQty,
        avlWeight: avlWeight,
        avlWastage: avlWastage,
      };
    }
  }

  return lotsList;
}

export function calculateTotalQuantityAndUnit(buyBill: TBillInfo[]) {
  let totalCrates = 0;
  let totalSacs = 0;
  let totalBoxes = 0;
  let totalBags = 0;
  let totalKgs = 0;
  let totalLds = 0;
  let totalPcs = 0;
  let total = 0;
  let totalWastage = 0;

  buyBill.forEach((buyBill) => {
    buyBill.lineItems.forEach((item) => {
      if (item.rateType !== 'RATE_PER_PACKAGE') {
        total += item.total;
      }

      const ratePerType = item.rateType;
      const weight = item.weight - item.wastage; // Adjust weight based on wastage

      switch (item.qtyUnit) {
        case 'CRATES':
          if (isRatePerQuantity(ratePerType)) {
            totalCrates += item.qty - item.wastage;
            totalKgs += weight;
          } else {
            totalCrates += item.qty;
            totalKgs += item.weight - item.wastage;
          }
          break;
        case 'SACS':
          if (isRatePerQuantity(ratePerType)) {
            totalSacs += item.qty - item.wastage;
            totalKgs += weight;
          } else {
            totalSacs += item.qty;
            totalKgs += item.weight - item.wastage;
          }
          break;
        case 'BOXES':
          if (isRatePerQuantity(ratePerType)) {
            totalBoxes += item.qty - item.wastage;
            totalKgs += weight;
          } else {
            totalBoxes += item.qty;
            totalKgs += item.weight - item.wastage;
          }
          break;
        case 'BAGS':
          if (isRatePerQuantity(ratePerType)) {
            totalBags += item.qty - item.wastage;
            totalKgs += weight;
          } else {
            totalBags += item.qty;
            totalKgs += item.weight - item.wastage;
          }
          break;
        case 'KGS':
          totalKgs += weight;
          break;
        case 'LOADS':
          totalLds += weight;
          break;
        case 'PIECES':
          if (item.rateType === 'RATE_PER_PACKAGE') {
            switch (item.pkgUnit) {
              case 'CRATES':
                totalCrates += item.qty - item.wastage;
                break;
              case 'SACS':
                totalSacs += item.qty - item.wastage;
                break;
              case 'BOXES':
                totalBoxes += item.qty - item.wastage;
                break;
              case 'BAGS':
                totalBags += item.qty - item.wastage;
                break;
              default:
                break;
            }
          } else if (item.rateType === 'RATE_PER_UNIT') {
            totalPcs += item.qty - item.wastage;
          } else {
            totalPcs += item.weight - item.wastage;
          }
          break;
        default:
          break;
      }
    });
  });

  // Formatting result string
  let result = '';
  if (totalBoxes !== 0) result += `${totalBoxes} BX |`;
  if (totalCrates !== 0) result += ` ${totalCrates} C |`;
  if (totalSacs !== 0) result += ` ${totalSacs} S |`;
  if (totalBags !== 0) result += ` ${totalBags} BG |`;
  if (totalKgs !== 0) result += ` ${totalKgs} KGS |`;
  if (totalLds !== 0) result += ` ${totalLds} LDS |`;
  if (totalPcs !== 0) result += ` ${totalPcs} PCS |`;

  return {
    wastage: totalWastage,
    quantity: result.slice(0, -2), // Removing the last "|"
    total: total,
  };
}

export default {
  isRatePerQuantity,
  getGroupedBags,
  getAvailableQtyWeights,
}

