import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMarkets } from '../actions/loginService';
import { getProfile } from '../actions/profileService';

const initialState = {
  businessDtls: {},
  personalDtls: {},
  allMarketDetails: [],
  loading: true,
};

export const fetchProfileDetails = createAsyncThunk(
  'fetch/profileData',
  async (click_id) => {
    try {
      const { data } = await getProfile(click_id);
      return data;
    } catch (err) {
      return {};
    }
  }
);

export const fetchAllMarketDetails = createAsyncThunk(
  'fetch/allMarketData',
  async () => {
    try {
      const { data } = await getAllMarkets();
      return data;
    } catch (err) {
      return {};
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    updateProfileDetails: (state, action) => {
      state.businessDtls = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      state.businessDtls = action.payload.data.businessDtls;
      state.personalDtls = action.payload.data.personalDtls;
      state.loading = false;
    });

    builder.addCase(fetchAllMarketDetails.fulfilled, (state, action) => {
      state.allMarketDetails = action.payload.data;
    });
  },
});

export const { updateProfileDetails } = profileSlice.actions;
export default profileSlice.reducer;
