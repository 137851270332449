import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAdditionDeduction, TPartnerData } from "../types";
import { ActiveLotInfo, AddBillLotInfo } from "../types/smartchart/addBill";
import moment from "moment";
import { TCaBillSetting } from "../types/settings";


type TInitialState = {
    currentStep: number,
    selectedBuyer: TPartnerData[],
    activeLots: ActiveLotInfo[],
    selectedLots: AddBillLotInfo[],
    billDate: string,
    selectedLot: AddBillLotInfo,
    grossTotal: number,
    selectedLotIndex: number,
    formSettings:number[],
}

const initialState: TInitialState = {
    currentStep: 1,
    selectedBuyer: [{
        isSelectedElements: false,
        trader: false,
        partyId: 0,
        profilePic: "",
        partyName: "",
        partyType: "BUYER",
        mobile: "",
        shortName: ""
    }],
    selectedLots: [],
    activeLots: [],
    billDate: moment().format('YYYY-MM-DD'),
    selectedLot: {
        id: 0,
        lotId: "",
        qrCode: "",
        date: "",
        lotNumber: "",
        avlQty: 0,
        avlWastage: 0,
        avlWeight: 0,
        cropId: 0,
        cropName: "",
        imageUrl: "",
        qty: "",
        weight: "",
        wastage: "",
        netWeight: "",
        rateType: "",
        quanityType: "CRATES",
        sellRate: "",
        buyRate: "",
        sellTotal: "",
        buyTotal: "",
        individualWeights: [],
        lotIndividualWeights: [],
        isBuyRateChanged: false,
        totalQty: 0,
        totalWastage: 0,
        totalWeight: 0,
        pkgUnit: "",
        farmerId: 0,
        lotStageId: '',
        subLotId: 0,
        mnLotId: "",
        mnSubLotId: "",
        transporterId: 0,
        lotDate: '',
        useLotDate: false,
        isMainLot: false,
        index: 0,
        copyLength: 0
    },
    grossTotal: 0,
    selectedLotIndex: 0,
    formSettings: [],
}

export const addBillSlice = createSlice({
    name: "addBill",
    initialState: initialState,
    reducers: {
        resetAddBill: (state) => {
            return {
                ...initialState,
            }
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setActiveLots: (state, action) => {
            state.activeLots = action.payload;
        },
        setSelectedBuyer: (state, action) => {
            state.selectedBuyer = action.payload;
        },
        setSelectedLots: (
            state,
            action: PayloadAction<{
                lots: AddBillLotInfo[]
            }>
        ) => {
            state.selectedLots = action.payload.lots;
            let grossTotal = state.selectedLots.reduce((total, value) => total = Number(total) + Number(value.buyTotal), 0).toFixed(2);
            state.grossTotal = Number(grossTotal);

        },
        setBillDate: (state, action) => {
            state.billDate = action.payload;
        },
        setSelectedLot: (state, action:PayloadAction<{
            lot: AddBillLotInfo,
            index:number,
        }> ) => {
            state.selectedLot = action.payload.lot;
            state.selectedLotIndex = action.payload.index;
        },
        setGrossTotal: (state, action) => {
            state.grossTotal = action.payload;
        },
        setFormSettings: (state, action) => {
            state.formSettings = action.payload;
        }
    }
});
export const {
    setCurrentStep,
    setActiveLots,
    setSelectedBuyer,
    setSelectedLots,
    setBillDate,
    setSelectedLot,
    setGrossTotal,
    resetAddBill,
    setFormSettings,
} = addBillSlice.actions;

export default addBillSlice.reducer;
