import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TBillCrop, TPartnerData } from "../types";
import moment from "moment";
import { CreateLot, LotBag } from "../types/smartchart/createLot";
import { OnArrivalLot } from "../types/smartchart/onArrival";
import { isLotAuctioned } from "../helpers/smartChart";


type TInitialState = {
    selectedFarmer: TPartnerData[],
    selectedTransporter: TPartnerData[],
    useLotDate: boolean,
    lotDate: string,
    selectedCrops: CreateLot[],
    selectedCrop: CreateLot,
    selectedCropIndex: number,
    lotCreated: boolean,
    isEdit: boolean,
    lotId: string,
    deletedCrops: CreateLot[],
    getTransporterInv: boolean,
}

const initialState: TInitialState = {
    lotId: '',
    selectedFarmer: [],
    lotDate: moment().format('YYYY-MM-DD'),
    selectedTransporter: [],
    useLotDate: false,
    selectedCrops: [],
    selectedCrop: {
        cropId: 0,
        cropName: '',
        cropUrl: '',
        cropSufx: '',
        mnLotId: '',
        mnSubLotId: '',
        pkgUnit: '',
        qtyUnit: '',
        rateType: '',
        qty: "",
        weight: "",
        wastage: "",
        netWeight: '',
        bags: [],
        status: 0,
        id: 0,
        qrCode: '',
        hasBags: false,
        deletedBags: [],
        isSold: false
    },
    selectedCropIndex: 0,
    lotCreated: false,
    isEdit: false,
    deletedCrops: [],
    getTransporterInv: false,
}

export const createLotSlice = createSlice({
    name: "createLot",
    initialState: initialState,
    reducers: {
        setInitialData: (state) => {
            state.lotId = '';
            state.isEdit = false;
            state.selectedFarmer = [];
            state.selectedTransporter = [];
            state.selectedCrops = [];
            state.lotDate = moment().format('YYYY-MM-DD');
            state.useLotDate = false;
            state.deletedCrops = [];
        },
        setSelectedFarmer: (state, action) => {
            state.selectedFarmer = action.payload;
        },
        setSelectedTransporter: (state, action) => {
            state.selectedTransporter = action.payload;
            state.getTransporterInv = true;
        },
        setLotDate: (state, action) => {
            state.lotDate = action.payload;
        },
        setUseLotDate: (state, action) => {
            state.useLotDate = action.payload;
        },
        setTransInventory: (state, action) => {
            state.getTransporterInv = action.payload;
        },
        addLotCropItem: (state, action: PayloadAction<{
            crops: Array<CreateLot>;
        }>) => {
            state.selectedCrops.push(...action.payload.crops);
        },
        updateCropInfo: (
            state,
            action: PayloadAction<{
                crops: CreateLot[];
                deletedCrops?: CreateLot[],
            }>
        ) => {
            state.selectedCrops = action.payload.crops;
            state.deletedCrops = action.payload.deletedCrops ?? state.deletedCrops;
        },
        setIndividualWeights: (state, action: PayloadAction<{
            selectedCrop: CreateLot,
            selectedIndex: number,
        }>) => {
            state.selectedCrop = action.payload.selectedCrop;
            state.selectedCropIndex = action.payload.selectedIndex;
        },
        setLotCreated: (state, action) => {
            state.lotCreated = action.payload;
        },
        setEditLot: (state, action: PayloadAction<{
            lot: OnArrivalLot,
        }>) => {
            let lot = action.payload.lot;
            state.lotId = lot.lotId;
            state.isEdit = true;
            state.lotDate = lot.date;
            state.useLotDate = lot.useLotDate;
            state.selectedFarmer = [{
                isSelectedElements: false,
                trader: false,
                partyId: lot.farmerId,
                profilePic: lot.profilePic,
                partyName: lot.farmerName,
                partyType: "FARMER",
                mobile: lot.farmerMobile,
                shortName: lot.farmerShortName,
                address: { addressLine: lot.farmerAddress },
            }];
            if (lot.transporterId > 0) {
                state.selectedTransporter = [{
                    isSelectedElements: false,
                    trader: false,
                    partyId: lot.transporterId,
                    profilePic: '',
                    partyName: lot.transporterName,
                    partyType: "TRANSPORTER",
                    mobile: lot.transporterMobile,
                    shortName: ""
                }];
            }else{
                state.selectedTransporter = [];
            }
            let crops: CreateLot[] = [];
            lot.subLots.forEach((subLot) => {
                let bags: LotBag[] = [];
                if (subLot.bags != null) {
                    bags = subLot.bags.map((bag) => {
                        return {
                            id: bag.id,
                            status: 2,
                            netWeight: bag.netWeight.toFixed(2),
                            wastage: bag.wastage.toFixed(2),
                            weight: bag.weight.toFixed(2),
                            sold: bag.sold,
                        }
                    });
                }
                crops.push({
                    cropId: subLot.cropId,
                    cropName: subLot.cropName,
                    cropUrl: subLot.imageUrl,
                    cropSufx: subLot.cropSufx,
                    mnLotId: subLot.mnLotId,
                    mnSubLotId: subLot.mnSubLotId,
                    pkgUnit: subLot.pkgUnit,
                    qtyUnit: subLot.qtyUnit,
                    rateType: subLot.rateType,
                    qty: subLot.qty.toFixed(2),
                    weight: subLot.weight.toFixed(2),
                    wastage: subLot.wastage.toFixed(2),
                    netWeight: subLot.totalNetWt.toFixed(2),
                    bags: bags,
                    status: 2,
                    id: subLot.subLotId,
                    qrCode: subLot.qrCode,
                    hasBags: bags.length > 0,
                    deletedBags: [],
                    isSold: isLotAuctioned(subLot),
                });
            });
            state.selectedCrops = crops;
            state.deletedCrops = [];
            state.getTransporterInv = true;
        },
    }
});
export const {
    setInitialData,
    setSelectedFarmer,
    setLotDate,
    setSelectedTransporter,
    setUseLotDate,
    updateCropInfo,
    addLotCropItem,
    setIndividualWeights,
    setLotCreated,
    setEditLot,
    setTransInventory,
} = createLotSlice.actions;

export default createLotSlice.reducer;
