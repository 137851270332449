import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TPartner,
  TLedgerTransaction,
  TDetailedTransaction,
  TAdvanceTransactions,
  TRecordPaymentBill,
  TRecordPaymentDetail,
  TAdvanceRecordPaymentDetail,
  TInventoryLedgerPartner,
  TInventoryLedgerTransaction,
  TInventory,
  TInventoryRecordPaymentDetail,
} from "../types/ledgers";
type TInitialState = {
  type: "BUYER" | "SELLER" | "TRANSPORTER" | "INVENTORY" | "";
  totalOutStgAmt: number;
  sellers: TPartner[];
  buyers: TPartner[];
  transporters: TPartner[];
  inventoryLedgerPartners: TInventoryLedgerPartner[];
  totalInventory: TInventory[];
  inventoryLedgerData: {
    balance: TInventory[];
    details: TInventoryLedgerTransaction[];
    totalCollected: TInventory[];
    totalGiven: TInventory[];
  };
  selectedPartner: TPartner | null;
  seletedInventoryLedgerPartner: TInventoryLedgerPartner | null;
  transactionHistory: TLedgerTransaction[];
  detailedHistory: TDetailedTransaction[];
  advances: TAdvanceTransactions[];
  advanceBal: number;
  outStdRcvPayble: number;
  totalRcvdPaid: number;
  totalTobePaidRcvd: number;
  //
  openingBalance: number;
  totalOutStandingBalance: number;
  totalPaid: number;
  totalToBePaid: number;
  //
  totalAdvBal: number;
  totalCollected: number;
  totalGiven: number;
  openPaymentRecordDetail: boolean;
  refId: string;
  recordPaymentViewType: "ledger" | "advance" | "inventory";
  recordPaymentLedgerDetail: TRecordPaymentDetail | null;
  advanceRecordPaymentDetail: TAdvanceRecordPaymentDetail | null;
  inventoryRecordPaymentDetail: TInventoryRecordPaymentDetail | null;
  showRecordPaymentModal: boolean;
  showAdvanceRecordPaymentModal: boolean;
  recordPaymentMode: "CREATE" | "EDIT" | "";
  selectedBills: { [billId: number]: TRecordPaymentBill };
  totalBillPayble: number;
  totalBillPaid: number;
  recordPaymentSubmitStatus: "" | "LOADING" | "COMPLETED";
  refreshLedger: boolean;
};
const initialState: TInitialState = {
  type: "",
  totalOutStgAmt: 0,
  sellers: [],
  buyers: [],
  transporters: [],
  inventoryLedgerPartners: [],
  totalInventory: [],
  inventoryLedgerData: {
    totalGiven: [],
    totalCollected: [],
    balance: [],
    details: [],
  },
  selectedPartner: null,
  seletedInventoryLedgerPartner: null,
  transactionHistory: [],
  detailedHistory: [],
  advances: [],
  advanceBal: 0,
  outStdRcvPayble: 0,
  totalRcvdPaid: 0,
  totalTobePaidRcvd: 0,
  //
  openingBalance: 0,
  totalOutStandingBalance: 0,
  totalPaid: 0,
  totalToBePaid: 0,
  //
  totalAdvBal: 0,
  totalCollected: 0,
  totalGiven: 0,
  //record payment
  openPaymentRecordDetail: false,
  refId: "",
  recordPaymentViewType: "ledger",
  recordPaymentLedgerDetail: null,
  advanceRecordPaymentDetail: null,
  inventoryRecordPaymentDetail: null,
  showRecordPaymentModal: false,
  showAdvanceRecordPaymentModal: false,
  recordPaymentMode: "",
  selectedBills: {},
  totalBillPayble: 0,
  totalBillPaid: 0,
  recordPaymentSubmitStatus: "",
  refreshLedger: false,
};
const ledgerSlice = createSlice({
  name: "ledger",
  initialState: initialState,
  reducers: {
    initLedger: (
      state,
      action: PayloadAction<{
        type: "BUYER" | "SELLER" | "TRANSPORTER" | "INVENTORY";
      }>
    ) => {
      state.type = action.payload.type;
    },
    updateSellerLedger: (
      state,
      action: PayloadAction<{
        partners: TPartner[];
        advanceBal: number;
        totalOutStgAmt: number;
      }>
    ) => {
      state.sellers = action.payload.partners;
      state.advanceBal = action.payload.advanceBal;
      state.totalOutStgAmt = action.payload.totalOutStgAmt;
    },
    updateBuyerLedger: (
      state,
      action: PayloadAction<{ partners: TPartner[]; totalOutStgAmt: number }>
    ) => {
      state.buyers = action.payload.partners;
      state.totalOutStgAmt = action.payload.totalOutStgAmt;
    },
    updateTransPorterLedger: (
      state,
      action: PayloadAction<{ partners: TPartner[]; totalOutStgAmt: number }>
    ) => {
      state.transporters = action.payload.partners;
      state.totalOutStgAmt = action.payload.totalOutStgAmt;
    },
    updateInventoryLedger: (
      state,
      action: PayloadAction<{
        summaryInfo: TInventoryLedgerPartner[];
        totalInventory: TInventory[];
        initPartnerSummary?: boolean;
      }>
    ) => {
      state.inventoryLedgerPartners = action.payload.summaryInfo;
      state.totalInventory = action.payload.totalInventory;
      // if (action.payload.summaryInfo.length > 0) {
      //   state.seletedInventoryLedgerPartner = action.payload.summaryInfo[0];
      // } else {
      //   state.seletedInventoryLedgerPartner = null;
      // }
    },

    updateInventoryLedgerSummary: (
      state,
      action: PayloadAction<{
        balance: TInventory[];
        details: TInventoryLedgerTransaction[];
        totalCollected: TInventory[];
        totalGiven: TInventory[];
      }>
    ) => {
      state.inventoryLedgerData.details = action.payload.details;
      state.inventoryLedgerData.balance = action.payload.balance;
      state.inventoryLedgerData.totalCollected = action.payload.totalCollected;
      state.inventoryLedgerData.totalGiven = action.payload.totalGiven;
    },
    showPartnerSummary: (
      state,
      action: PayloadAction<{
        partner: TPartner | null;
      }>
    ) => {
      console.log(action.payload.partner);
      state.selectedPartner = action.payload.partner;
    },
    showInventoryLedgerPartnerSummary: (
      state,
      action: PayloadAction<{ partner: TInventoryLedgerPartner | null }>
    ) => {
      state.seletedInventoryLedgerPartner = action.payload.partner;
    },
    setTransactionHistory: (
      state,
      action: PayloadAction<{
        history: TLedgerTransaction[];
        advanceBal: number;
        outStdRcvPayble: number;
        totalRcvdPaid: number;
        totalTobePaidRcvd: number;
      }>
    ) => {
      state.transactionHistory = action.payload.history;
      state.advanceBal = action.payload.advanceBal;
      state.outStdRcvPayble = action.payload.outStdRcvPayble;
      state.totalRcvdPaid = action.payload.totalRcvdPaid;
      state.totalTobePaidRcvd = action.payload.totalTobePaidRcvd;
    },
    setDetailedLedgers: (
      state,
      action: PayloadAction<{
        details: TDetailedTransaction[];
        openingBalance: number;
        totalOutStandingBalance: number;
        totalPaid: number;
        totalToBePaid: number;
      }>
    ) => {
      state.detailedHistory = action.payload.details;
      state.openingBalance = action.payload.openingBalance;
      state.totalOutStandingBalance = action.payload.totalOutStandingBalance;
      state.totalPaid = action.payload.totalPaid;
      state.totalToBePaid = action.payload.totalToBePaid;
    },
    setAdvanceLedgers: (
      state,
      action: PayloadAction<{
        advances: TAdvanceTransactions[];
        totalAdvBal: number;
        totalCollected: number;
        totalGiven: number;
      }>
    ) => {
      state.advances = action.payload.advances;
      state.totalAdvBal = action.payload.totalAdvBal;
      state.totalCollected = action.payload.totalCollected;
      state.totalGiven = action.payload.totalGiven;
    },
    showPaymentRecordDetail: (
      state,
      action: PayloadAction<{
        refId: string;
        recordPaymentViewType?: "ledger" | "advance" | "inventory";
        paymentDetail?: TRecordPaymentDetail;
        advancePaymentDetail?: TAdvanceRecordPaymentDetail;
        inventoryDetail?: TInventoryRecordPaymentDetail;
      }>
    ) => {
      if (action.payload.paymentDetail) {
        state.recordPaymentLedgerDetail = action.payload.paymentDetail;
      } else if (action.payload.advancePaymentDetail) {
        state.advanceRecordPaymentDetail = action.payload.advancePaymentDetail;
      } else if (action.payload.inventoryDetail) {
        state.inventoryRecordPaymentDetail = action.payload.inventoryDetail;
      } else {
        state.openPaymentRecordDetail = true;
        state.refId = action.payload.refId;
        if (action.payload.recordPaymentViewType) {
          state.recordPaymentViewType = action.payload.recordPaymentViewType;
        } else {
          state.recordPaymentViewType = "ledger";
        }
      }
      state.refreshLedger = false;
    },
    hiderPaymentRecordDetail: (state, action: PayloadAction<{}>) => {
      state.openPaymentRecordDetail = false;
      state.recordPaymentLedgerDetail = null;
      state.refId = "";
    },
    showRecordPayment: (
      state,
      action: PayloadAction<{
        mode?: "CREATE" | "EDIT";
        viewType?: "ledger" | "advance" | "inventory";
      }>
    ) => {
      state.showRecordPaymentModal = true;
      if (action.payload.mode === "EDIT") {
        state.recordPaymentMode = "EDIT";
      } else {
        state.recordPaymentMode = "CREATE";
      }
      if (action.payload.viewType) {
        state.recordPaymentViewType = action.payload.viewType;
      }
      state.refreshLedger = false;
    },
    hideRecordPayment: (state, action: PayloadAction<{}>) => {
      state.showRecordPaymentModal = false;
      state.totalBillPayble = 0;
      state.totalBillPaid = 0;
      state.selectedBills = {};
    },
    showAdvanceRecordPayment: (state, action: PayloadAction<{}>) => {
      state.showAdvanceRecordPaymentModal = true;
      state.refreshLedger = false;
    },
    hideAdvanceRecordPayment: (state, action: PayloadAction<{}>) => {
      state.showAdvanceRecordPaymentModal = false;
    },
    selectBill: (
      state,
      action: PayloadAction<{ bill: TRecordPaymentBill }>
    ) => {
      state.selectedBills[action.payload.bill.caBSeq] = action.payload.bill;
      state.totalBillPayble = parseFloat(
        (state.totalBillPayble + action.payload.bill.amount).toFixed(2)
      );
      state.totalBillPaid = parseFloat(
        (state.totalBillPaid + action.payload.bill.paidAmt).toFixed(2)
      );
    },
    unSelectBill: (
      state,
      action: PayloadAction<{ bill: TRecordPaymentBill }>
    ) => {
      state.totalBillPayble = parseFloat(
        (state.totalBillPayble - action.payload.bill.amount).toFixed(2)
      );
      state.totalBillPaid = parseFloat(
        (state.totalBillPaid - action.payload.bill.paidAmt).toFixed(2)
      );
      delete state.selectedBills[action.payload.bill.caBSeq];
    },
    restoreRecordPaymentSletedBills: (
      state,
      action: PayloadAction<{
        bills: { [id: number]: TRecordPaymentBill };
        totalBillPayble: number;
        totalBillPaid: number;
      }>
    ) => {
      state.selectedBills = action.payload.bills;
      state.totalBillPayble = action.payload.totalBillPayble;
      state.totalBillPaid = action.payload.totalBillPaid;
    },
    submitRecordPaymentStatus: (
      state,
      action: PayloadAction<{ status?: "LOADING" | "COMPLETED" | "" }>
    ) => {
      console.log(action.payload.status, "action.payload.status");
      if (action.payload.status === "LOADING") {
        state.recordPaymentSubmitStatus = "LOADING";
      } else if (action.payload.status === "") {
        state.recordPaymentSubmitStatus = "";
      } else {
        state.recordPaymentSubmitStatus = "COMPLETED";
        state.refreshLedger = true;
        state.showRecordPaymentModal = false;
        state.openPaymentRecordDetail = false;
      }
    },
  },
});
export const {
  initLedger,
  updateSellerLedger,
  updateBuyerLedger,
  showPartnerSummary,
  showInventoryLedgerPartnerSummary,
  setTransactionHistory,
  setDetailedLedgers,
  setAdvanceLedgers,
  showRecordPayment,
  hideRecordPayment,
  showAdvanceRecordPayment,
  hideAdvanceRecordPayment,
  selectBill,
  unSelectBill,
  restoreRecordPaymentSletedBills,
  showPaymentRecordDetail,
  hiderPaymentRecordDetail,
  submitRecordPaymentStatus,
  // transporter ledger
  updateTransPorterLedger,
  updateInventoryLedger,
  updateInventoryLedgerSummary,
} = ledgerSlice.actions;
export default ledgerSlice.reducer;
