import { TCaBillSetting } from "../types/settings";
const getEnvVariables = (): {
  API_BASE_URL: string;
  ENV: "DEV" | "PROD";
  PDF_SERVICE_BASE_API: string;
} => {
  if (window.location.host === "click.onoark.com") {
    return {
      API_BASE_URL: "https://api.onoark.com/v1/",
      ENV: "PROD",
      PDF_SERVICE_BASE_API: "https://dev-logs.onoark.com/", // "http://54.86.67.9:9001/v1/pdfgen",
    };
  } else {
    return {
      API_BASE_URL: "https://dev-api.onoark.com/v1/",
      ENV: "DEV",
      PDF_SERVICE_BASE_API: "https://dev-logs.onoark.com/",
    };
  }
};

export const SOURCE = "WEB";
export const { API_BASE_URL, ENV, PDF_SERVICE_BASE_API } = getEnvVariables();
export const QUANTITY = {
  CRATES: {
    unit: "CRATES",
    short_name: "C",
  },
  BAGS: {
    unit: "BAGS",
    short_name: "BG",
  },
  SACS: {
    unit: "SACS",
    short_name: "S",
  },
  BOXES: {
    unit: "BOXES",
    short_name: "BX",
  },
  KGS: {
    unit: "KGS",
    short_name: "KG",
  },
  LOADS: {
    unit: "LOADS",
    short_name: "LDS",
  },
  PIECES: {
    unit: "PIECES",
    short_name: "PCS",
  },
};
export const RATE_TYPE = {
  PER_KG: "RATE_PER_KG",
  PER_UNIT: "RATE_PER_UNIT",
};
export const BILL_SETTINGS = {
  COMMISSION: "COMMISSION",
  RETURN_COMMISSION: "RETURN_COMMISSION",
  TRANSPORTATION: "TRANSPORTATION",
  GOVT_LEVIES: "GOVT_LEVIES",
  ADVANCES: "ADVANCES",
  MANDI_FEE: "MANDI_FEE",
  OTHER_FEE: "OTHER_FEE",
  RENT: "RENT",
  LABOUR_CHARGES: "LABOUR_CHARGES",
  CASH_PAID: "CASH_PAID",
  CASH_RECEIVED: "CASH_RECEIVED",
};
