import classes from "./style.module.scss";
const PageLoading = () => {
  return (
    <div className="h-40 flex justify-center items-center">
      <div className={classes.lds_ripple}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default PageLoading;
