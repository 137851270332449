import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/appSlice";
import userReduer from "./reducers/userSlice";
import buyerReducer from "./reducers/buyerSlice";
import authReducer from "./reducers/authSlice";
import userInfoReducer from "./reducers/userInfoSlice";
import mandiInfoReducer from "./reducers/mandiProfile";
import stepsReducer from "./reducers/stepsSlice";
import billViewReducer from "./reducers/billViewSlice";
import selectedCropsReducer from "./reducers/selectedCropsSlice";
import transReducer from "./reducers/transSlice";
import billEditItemReducer from "./reducers/billEditItemSlice";
import step1DataReducer from "./reducers/step1DataSlice";
import paymentViewReducer from "./reducers/paymentViewSlice";
import ledgersSummaryReducer from "./reducers/ledgerSummarySlice";
import transpoReducer from "./reducers/transpoSlice";
import ledgersCustomDateReducer from "./reducers/ledgersCustomDateSlice";
import partnerReducer from "./reducers/partnerSlice";
import advanceReducer from "./reducers/advanceSlice";
import reportsReducer from "./reducers/reportsSlice";
import multiBillStepsReducer from "./reducers/multiBillSteps";
import billViewDisplaySliceReducer from "./reducers/billViewDisplaySlice";
import profileReducer from "./reducers/profileSlice";
import billBookReducer from "./reducers/billBookSlice";
import ledgerSlice from "./reducers/ledgerSlice";
import smartboardSlice from "./reducers/smartboardSlice";
import reportSlice from "./reducers/reportSlice";
import  smartChartSlice  from "./reducers/smartChartSilce";
import addBillSlice  from "./reducers/addBillSlice";
import  salesSummarySlice  from "./reducers/salesSummarySlice";
import createLotSlice from "./reducers/createLotSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    profile: profileReducer,
    user: userReduer,
    buyerInfo: buyerReducer,
    userInfo: userInfoReducer,
    mandiInfo: mandiInfoReducer,
    stepsInfo: stepsReducer,
    billViewInfo: billViewReducer,
    selectedCropsInfo: selectedCropsReducer,
    transInfo: transReducer,
    billEditItemInfo: billEditItemReducer,
    step1DataInfo: step1DataReducer,
    paymentViewInfo: paymentViewReducer,
    ledgerSummaryInfo: ledgersSummaryReducer,
    transpoInfo: transpoReducer,
    dates: ledgersCustomDateReducer,
    partnerInfo: partnerReducer,
    advanceInfo: advanceReducer,
    multiStepsInfo: multiBillStepsReducer,
    mandiDetails: billViewDisplaySliceReducer,
    reportsInfo: reportsReducer,
    billBookDetails: billBookReducer,
    ledger: ledgerSlice,
    smartboard: smartboardSlice,
    reports: reportSlice,
    smartchart: smartChartSlice,
    addBill: addBillSlice,
    salesSummary: salesSummarySlice,
    createLot: createLotSlice,
  },
});
export type TRootState = ReturnType<typeof store.getState>;
export default store;
