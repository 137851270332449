import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TCaBillSetting,
  TDefultSetting,
  TPdfSettings,
  TCaQuantitySetting,
} from "../types/settings";
import { TCrop, TPartnerData } from "../types";
type TinitialState = {
  farmerData: Array<TPartnerData>;
  transporterData: Array<any>;
  buyerData: Array<TPartnerData>;
  preferedCrops: TCrop[];
  allCrops: Array<TCrop>;
  defaultSystemSettings: Array<TDefultSetting>;
  caSettings: {
    buyBillRateType: string;
    sellBillRateType: string;
    buyBillSettings: TCaBillSetting[];
    buyBllAllSettings: TCaBillSetting[];
    sellBillSettings: TCaBillSetting[];
    sellBllAllSettings: TCaBillSetting[];
    qtySetting: { [cropId: number]: TCaQuantitySetting };
    wasteSetting: any[];
    sellBillCropSuffixStatus: boolean;
    buyBillCropSuffixStatus: boolean;
    sellBillNumberOfDaysEdit: number;
    buyBillNumberOfDaysEdit: number;
    buyBillAddSkipIndividualStatus: boolean;
    sellBillAddSkipIndividualStatus: boolean;
    buyBillOutBalformStatus: boolean;
    sellBillOutBalformStatus: boolean;
  };
  pdfSettings: TPdfSettings[];
};
const initialState: TinitialState = {
  farmerData: [],
  transporterData: [],
  buyerData: [],
  preferedCrops: [],
  allCrops: [],
  defaultSystemSettings: [],
  caSettings: {
    buyBillRateType: "",
    sellBillRateType: "",
    buyBillSettings: [],
    buyBllAllSettings: [],
    sellBillSettings: [],
    sellBllAllSettings: [],
    qtySetting: {},
    wasteSetting: [],
    sellBillCropSuffixStatus: false,
    buyBillCropSuffixStatus: false,
    sellBillNumberOfDaysEdit: 30,
    buyBillNumberOfDaysEdit: 30,
    buyBillAddSkipIndividualStatus: false,
    sellBillAddSkipIndividualStatus: false,
    buyBillOutBalformStatus: false,
    sellBillOutBalformStatus: false,
  }, // commission agent settings
  pdfSettings: [],
};
const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setFarmerData: (state, action) => {
      state.farmerData = action.payload;
    },
    setTransporterData: (state, action) => {
      state.transporterData = action.payload;
    },
    setBuyerData: (state, action) => {
      state.buyerData = action.payload;
    },
    setAllCrops: (state, action: PayloadAction<TCrop[]>) => {
      state.allCrops = action.payload;
    },
    setDefaultSettings: (state, action: PayloadAction<TDefultSetting[]>) => {
      state.defaultSystemSettings = action.payload;
    },
    setCaSettings: (
      state,
      action: PayloadAction<{
        buyBillSettings: TCaBillSetting[];
        buyBllAllSettings: TCaBillSetting[];
        sellBillSettings: TCaBillSetting[];
        sellBllAllSettings: TCaBillSetting[];
        buyBillRateType: string;
        sellBillRateType: string;
        quantitySettings: { [cropId: number]: TCaQuantitySetting };
        sellBillCropSuffixStatus: boolean;
        buyBillCropSuffixStatus: boolean;
        sellBillNumberOfDaysEdit: number;
        buyBillNumberOfDaysEdit: number;
        buyBillAddSkipIndividualStatus: boolean;
        sellBillAddSkipIndividualStatus: boolean;
        buyBillOutBalformStatusvalue: boolean;
        sellBillOutBalformStatusvalue: boolean;
      }>
    ) => {
      state.caSettings.buyBillSettings = action.payload.buyBillSettings;
      state.caSettings.buyBllAllSettings = action.payload.buyBllAllSettings;
      state.caSettings.sellBillSettings = action.payload.sellBillSettings;
      state.caSettings.sellBllAllSettings = action.payload.sellBllAllSettings;
      state.caSettings.buyBillRateType = action.payload.buyBillRateType;
      state.caSettings.sellBillRateType = action.payload.sellBillRateType;
      state.caSettings.qtySetting = action.payload.quantitySettings;
      state.caSettings.buyBillCropSuffixStatus =
        action.payload.buyBillCropSuffixStatus;
      state.caSettings.sellBillCropSuffixStatus =
        action.payload.sellBillCropSuffixStatus;
      state.caSettings.sellBillNumberOfDaysEdit =
        action.payload.sellBillNumberOfDaysEdit;
      state.caSettings.buyBillNumberOfDaysEdit =
        action.payload.buyBillNumberOfDaysEdit;
      state.caSettings.buyBillAddSkipIndividualStatus =
        action.payload.buyBillAddSkipIndividualStatus;
      state.caSettings.sellBillAddSkipIndividualStatus =
        action.payload.sellBillAddSkipIndividualStatus;
      state.caSettings.buyBillOutBalformStatus =
        action.payload.buyBillOutBalformStatusvalue;
      state.caSettings.sellBillOutBalformStatus =
        action.payload.sellBillOutBalformStatusvalue;
    },
    setPreferedCrops: (state, action: PayloadAction<TCrop[]>) => {
      state.preferedCrops = action.payload;
    },
    setPdfSettings: (state, action: PayloadAction<TPdfSettings[]>) => {
      state.pdfSettings = action.payload;
    },
  },
});

export const {
  setFarmerData,
  setTransporterData,
  setPreferedCrops,
  setAllCrops,
  setDefaultSettings,
  setCaSettings,
  setPdfSettings,
  setBuyerData,
} = appSlice.actions;
export default appSlice.reducer;
