import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TCropPurchaseSalesData,
  TFarmerBuyerData,
  TMycomissions,
  TOutstandingBalances,
  TSalesPurchaseData,
  TSellBuyTransactions,
} from "../types/smartboard";
type TInitialState = {
  type: "BUYER" | "SELLER" | "";
  outStandingBal: TOutstandingBalances | null;
  farmerData: TFarmerBuyerData[];
  buyerData: TFarmerBuyerData[];
  commissionEarns: TMycomissions | null;
  buyRecentTxs: TSellBuyTransactions[];
  sellRecentTxs: TSellBuyTransactions[];
  salesReprtData: TSalesPurchaseData | null;
  purchaseReprtData: TSalesPurchaseData | null;
  cropSalesData: TCropPurchaseSalesData[];
  cropPurchaseData: TCropPurchaseSalesData[];
  selectedCrop: TCropPurchaseSalesData | null;
  selectedPurchaseCrop: TCropPurchaseSalesData | null;
};
const initialState: TInitialState = {
  type: "",
  outStandingBal: null,
  farmerData: [],
  buyerData: [],
  commissionEarns: null,
  buyRecentTxs: [],
  sellRecentTxs: [],
  salesReprtData: null,
  purchaseReprtData: null,
  cropSalesData: [],
  cropPurchaseData: [],
  selectedCrop: null,
  selectedPurchaseCrop: null,
};
const smartboardSlice = createSlice({
  name: "smartboard",
  initialState: initialState,
  reducers: {
    updateSmartboardData: (
      state,
      action: PayloadAction<{
        outStandingBal: TOutstandingBalances;
        farmerData: TFarmerBuyerData[];
        buyerData: TFarmerBuyerData[];
        commissionEarns: TMycomissions;
        buyRecentTxs: TSellBuyTransactions[];
        sellRecentTxs: TSellBuyTransactions[];
        salesReprtData: TSalesPurchaseData;
        purchaseReprtData: TSalesPurchaseData;
        cropSalesData: TCropPurchaseSalesData[];
        cropPurchaseData: TCropPurchaseSalesData[];
      }>
    ) => {
      state.outStandingBal = action.payload.outStandingBal;
      state.farmerData = action.payload.farmerData;
      state.buyerData = action.payload.buyerData;
      state.commissionEarns = action.payload.commissionEarns;
      state.buyRecentTxs = action.payload.buyRecentTxs;
      state.sellRecentTxs = action.payload.sellRecentTxs;
      state.salesReprtData = action.payload.salesReprtData;
      state.purchaseReprtData = action.payload.purchaseReprtData;
      state.cropSalesData = action.payload.cropSalesData;
      state.cropPurchaseData = action.payload.cropPurchaseData;
    },
    sellBuySelectedCropData: (
      state,
      action: PayloadAction<{
        selectedCrop?: TCropPurchaseSalesData;
        selectedPurchaseCrop?: TCropPurchaseSalesData;
      }>
    ) => {
      if (action.payload.selectedCrop) {
        state.selectedCrop = action.payload.selectedCrop;
      }
      if (action.payload.selectedPurchaseCrop) {
        state.selectedPurchaseCrop = action.payload.selectedPurchaseCrop;
      }
    },
  },
});
export const { updateSmartboardData, sellBuySelectedCropData } =
  smartboardSlice.actions;
export default smartboardSlice.reducer;
