import { createSlice } from "@reduxjs/toolkit";
import {
  IS_AUTH,
  LOGIN_RESPONSE,
  PLAN_STATUS,
  CLIENT_ID,
  SELECTED_LANGUAGE,
  SYSTEM_SETTING,
  BUSINESS_DETAILS,
  PERSONAL_DETAILS,
  LANGUAGE_DATA,
} from "../constants/storage-keys";
console.log(
  BUSINESS_DETAILS,
  localStorage.getItem(BUSINESS_DETAILS),
  "BUSINESS_DETAILS"
);
type TInitialState = {
  isAuthenticated: boolean;
  clientId: string;
  loginResponse: any;
  systemSettingsData: any;
  statusPlan: string;
  selectedLangId: number;
  businessDetails: {
    businessId: number;
    businessName: string;
    shortCode: string;
    businessType: string;
    shopNum: string;
    contactName: string;
    mobile: string;
    altMobile: string;
    imageUrl: string | null;
    marketId: number;
    marketName: string;
    other: boolean;
    businessAddress: {
      addressLine: string;
      dist: string;
      state: string;
      pincode: number;
    };
  };
  personalDetails: {
    ownerName: string;
    contactNum: string;
  };
  languageData: any;
};
const getInitialState = () => {
  console.log("coming");
  return {
    isAuthenticated: localStorage.getItem(IS_AUTH) === "true" ? true : false,
    clientId: localStorage.getItem(CLIENT_ID) || "",
    loginResponse: JSON.parse(localStorage.getItem(LOGIN_RESPONSE) || "{}"),
    systemSettingsData: JSON.parse(
      localStorage.getItem(SYSTEM_SETTING) || "{}"
    ),
    statusPlan: localStorage.getItem(PLAN_STATUS) || "",
    selectedLangId: parseInt(localStorage.getItem(SELECTED_LANGUAGE) || "0"),
    businessDetails: JSON.parse(localStorage.getItem(BUSINESS_DETAILS) || "{}"),
    personalDetails: JSON.parse(localStorage.getItem(PERSONAL_DETAILS) || "{}"),
    languageData: JSON.parse(localStorage.getItem(LANGUAGE_DATA) || "{}"),
  };
};
const initialState: TInitialState = getInitialState();
const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    setBusinessDtls: (state, action) => {
      state.businessDetails = JSON.parse(action.payload) || "{}";
    },
    setPersonalDtls: (state, action) => {
      state.personalDetails = JSON.parse(action.payload) || "{}";
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
