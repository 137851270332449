import { IndexedDbConfig } from "../../constants/storage-keys";
const DB_VERSION = IndexedDbConfig.version;
const INDEXED_DB_NAME = IndexedDbConfig.db_name;
const STORE_METADATA = "store_metadata";
export const initIDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(
      IndexedDbConfig.db_name,
      IndexedDbConfig.version
    );
    request.onerror = (event) => {
      console.log("indexed db error ", event);
      reject(event);
    };
    request.onupgradeneeded = (event) => {
      const DB = request.result;
      DB.createObjectStore(STORE_METADATA, { keyPath: "store_name" });
      IndexedDbConfig.tables.forEach((table) => {
        DB.createObjectStore(table.name, { keyPath: table.keyPath });
      });
    };
  });
};
export const clearIDB = () => {
  IndexedDbConfig.tables.forEach(async (table) => {
    await deleteData(table.name);
  });
};
export const storeData = async (
  store_name: string,
  data: any[],
  action_type: "add" | "update"
) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(INDEXED_DB_NAME, DB_VERSION);
    request.onsuccess = () => {
      const DB = request.result;
      if (data.length > 0) {
        const objectStore = DB.transaction(store_name, "readwrite").objectStore(
          store_name
        );
        if (data.length > 1) {
          for (let row of data) {
            if (action_type === "update") {
              objectStore.put(row);
            } else {
              objectStore.add(row);
            }
          }
        } else {
          if (action_type === "update") {
            objectStore.put(data[0]);
          } else {
            objectStore.add(data[0]);
          }
        }
      }
      const metaDataObjectStore = DB.transaction(
        STORE_METADATA,
        "readwrite"
      ).objectStore(STORE_METADATA);
      const deleteRequest = metaDataObjectStore.delete(store_name);
      deleteRequest.onsuccess = () => {
        metaDataObjectStore.add({
          store_name: store_name,
          last_update_time: new Date(),
        });
        DB.close();
      };
      resolve(null);
    };
    request.onerror = (event) => {
      console.log("store data error", event);
    };
  });
};
type Tfilter = {
  [key: string]: string | number;
};
export const getData = <T>(
  store_name: string,
  key: string | number = "",
  filter: Tfilter | null = null
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(INDEXED_DB_NAME, DB_VERSION);
    request.onsuccess = () => {
      const DB = request.result;
      const objectstoreTransaction = DB.transaction(
        store_name,
        "readwrite"
      ).objectStore(store_name);
      if (!key && filter === null) {
        const objectStoreRequest = objectstoreTransaction.getAll();
        objectStoreRequest.onsuccess = () => {
          DB.close();
          resolve(<T>objectStoreRequest.result);
        };
      } else if (key && filter === null) {
        const objectStoreRequest = objectstoreTransaction.get(key);
        objectStoreRequest.onsuccess = () => {
          DB.close();
          resolve(objectStoreRequest.result);
        };
      } else if (!key && filter !== null) {
        const objectStoreRequest = objectstoreTransaction.getAll();
        objectStoreRequest.onsuccess = () => {
          DB.close();
          resolve(<T>objectStoreRequest.result);
        };
      }
    };
  });
};
export const deleteData = (store_name: string, key?: string | number) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(INDEXED_DB_NAME, DB_VERSION);
    request.onsuccess = () => {
      const DB = request.result;
      const objectstoreTransaction = DB.transaction(store_name, "readwrite");
      const objectstore = objectstoreTransaction.objectStore(store_name);
      if (key) {
        objectstore.delete(key);
      } else {
        objectstore.clear();
      }
      objectstoreTransaction.oncomplete = () => {
        resolve(null);
      };
      objectstoreTransaction.onerror = () => {
        reject();
      };
    };
  });
};
export const dropDB = () => {
  return new Promise((resolve, reject) => {
    let req = indexedDB.deleteDatabase(INDEXED_DB_NAME);
    req.onsuccess = function () {
      resolve({ deleted: true, mesaage: "deleted successfully" });
    };
    req.onerror = function () {
      resolve({ deleted: false, mesaage: "something went wrong" });
    };
    req.onblocked = function () {
      resolve({
        deleted: false,
        mesaage: "Couldn't delete database due to the operation being blocked",
      });
    };
  });
};
