import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TCrop } from "../types";
import { TPartner } from "../types/ledgers";
import {
  TCoolieRentDailySummary,
  TDailySummary,
  TGrossBuyerSellerExpenses,
  TGrossBuyerSellerSummary,
  TGrossProfitSummary,
  TGrossQtyInfo,
  TPaidReceivedDailySummary,
  TSalesPurchaseDailySummary,
  TSalesPurchaseSummary,
  TTransportoDailySummary,
} from "../types/reports";
type TInitialState = {
  type: "BUYER" | "SELLER" | "";
  // daily  summary
  dailySalesSummary: TSalesPurchaseDailySummary | null;
  dailyPurchaseSummary: TSalesPurchaseDailySummary | null;
  transportoSummary: TTransportoDailySummary | null;
  salesPaymentSummary: TPaidReceivedDailySummary | null;
  purchasePaymentSumary: TPaidReceivedDailySummary | null;
  purchaseCommSummary: TCoolieRentDailySummary | null;
  salesCommSummary: TCoolieRentDailySummary | null;
  coolieSummary: TCoolieRentDailySummary | null;
  rentSummary: TCoolieRentDailySummary | null;
  dailySummaryObject: TDailySummary | null;
  // gross prrofits
  totalCOGS: number;
  totalRevenue: number;
  grossProfit: number;
  totalSellerQtyInfo: TGrossQtyInfo[];
  totalBuyerQtyInfo: TGrossQtyInfo[];
  sellersInfo: TGrossBuyerSellerSummary[];
  buyersInfo: TGrossBuyerSellerSummary[];
  sellerExpenses: TGrossBuyerSellerExpenses | null;
  buyerExpenses: TGrossBuyerSellerExpenses | null;
  // salesReports
  salesSummary: TSalesPurchaseSummary | null;
  purchaseSummary: TSalesPurchaseSummary | null;
  selectedPartner: TPartner | null;
  sellers: TPartner[];
  buyers: TPartner[];
  selectedCrop: TCrop | null;
  cropSummary: TSalesPurchaseSummary | null;
  grossSummaryObject: TGrossProfitSummary | null;
  reportsActiveTab: string;
};
const initialState: TInitialState = {
  type: "",
  dailySalesSummary: null,
  dailyPurchaseSummary: null,
  transportoSummary: null,
  salesPaymentSummary: null,
  purchasePaymentSumary: null,
  purchaseCommSummary: null,
  salesCommSummary: null,
  coolieSummary: null,
  rentSummary: null,
  dailySummaryObject: null,
  totalCOGS: 0,
  totalRevenue: 0,
  grossProfit: 0,
  totalSellerQtyInfo: [],
  totalBuyerQtyInfo: [],
  sellersInfo: [],
  buyersInfo: [],
  sellerExpenses: null,
  buyerExpenses: null,
  salesSummary: null,
  purchaseSummary: null,
  selectedPartner: null,
  sellers: [],
  buyers: [],
  selectedCrop: null,
  cropSummary: null,
  grossSummaryObject: null,
  reportsActiveTab: "dailySummary",
};
const reportSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    updateDailySalesSummary: (
      state,
      action: PayloadAction<{
        dailySalesSummary: TSalesPurchaseDailySummary;
        dailyPurchaseSummary: TSalesPurchaseDailySummary;
        transportoSummary: TTransportoDailySummary;
        salesPaymentSummary: TPaidReceivedDailySummary;
        purchasePaymentSumary: TPaidReceivedDailySummary;
        purchaseCommSummary: TCoolieRentDailySummary;
        salesCommSummary: TCoolieRentDailySummary;
        coolieSummary: TCoolieRentDailySummary;
        rentSummary: TCoolieRentDailySummary;
        dailySummaryObject: TDailySummary;
      }>
    ) => {
      state.dailySalesSummary = action.payload.dailySalesSummary;
      state.dailyPurchaseSummary = action.payload.dailyPurchaseSummary;
      state.transportoSummary = action.payload.transportoSummary;
      state.salesPaymentSummary = action.payload.salesPaymentSummary;
      state.purchasePaymentSumary = action.payload.purchasePaymentSumary;
      state.purchaseCommSummary = action.payload.purchaseCommSummary;
      state.salesCommSummary = action.payload.salesCommSummary;
      state.coolieSummary = action.payload.coolieSummary;
      state.rentSummary = action.payload.rentSummary;
      state.dailySummaryObject = action.payload.dailySummaryObject;
    },
    updateGrossProfitsSummary: (
      state,
      action: PayloadAction<{
        totalCOGS: number;
        totalRevenue: number;
        grossProfit: number;
        totalSellerQtyInfo: TGrossQtyInfo[];
        totalBuyerQtyInfo: TGrossQtyInfo[];
        sellersInfo: TGrossBuyerSellerSummary[];
        buyersInfo: TGrossBuyerSellerSummary[];
        sellerExpenses: TGrossBuyerSellerExpenses;
        buyerExpenses: TGrossBuyerSellerExpenses;
        grossSummaryObject: TGrossProfitSummary;
      }>
    ) => {
      state.totalCOGS = action.payload.totalCOGS;
      state.totalRevenue = action.payload.totalRevenue;
      state.grossProfit = action.payload.grossProfit;
      state.totalSellerQtyInfo = action.payload.totalSellerQtyInfo;
      state.totalBuyerQtyInfo = action.payload.totalBuyerQtyInfo;
      state.sellersInfo = action.payload.sellersInfo;
      state.buyersInfo = action.payload.buyersInfo;
      state.sellerExpenses = action.payload.sellerExpenses;
      state.buyerExpenses = action.payload.buyerExpenses;
      state.grossSummaryObject = action.payload.grossSummaryObject;
    },
    updateSalesSummary: (
      state,
      action: PayloadAction<{
        salesSummary: TSalesPurchaseSummary;
      }>
    ) => {
      state.salesSummary = action.payload.salesSummary;
    },
    updatePurchaseSummary: (
      state,
      action: PayloadAction<{
        purchaseSummary: TSalesPurchaseSummary;
      }>
    ) => {
      state.purchaseSummary = action.payload.purchaseSummary;
    },
    updateSellerDetails: (
      state,
      action: PayloadAction<{
        partners: TPartner[];
      }>
    ) => {
      state.sellers = action.payload.partners;
    },
    showPartnerSummary: (
      state,
      action: PayloadAction<{
        partner: TPartner | null;
      }>
    ) => {
      state.selectedPartner = action.payload.partner;
    },
    updateBuyerDetails: (
      state,
      action: PayloadAction<{
        partners: TPartner[];
      }>
    ) => {
      state.buyers = action.payload.partners;
    },
    showCropSummary: (
      state,
      action: PayloadAction<{
        crop: TCrop | null;
      }>
    ) => {
      state.selectedCrop = action.payload.crop;
    },
    updateCropSummary: (
      state,
      action: PayloadAction<{
        cropSummary: TSalesPurchaseSummary;
      }>
    ) => {
      state.cropSummary = action.payload.cropSummary;
    },
    updateReportsActiveTab: (
      state,
      action: PayloadAction<{
        reportsActiveTab: string;
      }>
    ) => {
      state.reportsActiveTab = action.payload.reportsActiveTab;
    },
  },
});
export const {
  updateDailySalesSummary,
  updateGrossProfitsSummary,
  updateSalesSummary,
  updatePurchaseSummary,
  showPartnerSummary,
  updateBuyerDetails,
  updateSellerDetails,
  showCropSummary,
  updateCropSummary,
  updateReportsActiveTab,
} = reportSlice.actions;
export default reportSlice.reducer;
