import { BrowserRouter } from "react-router-dom";
import RoutesConfig from "./routes/routesConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.scss";
import { initIDB } from "./services/indexedDB";
initIDB();
const App = () => {
  return (
    <>
      <BrowserRouter>
        <RoutesConfig />
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};
export default App;
