export const IS_AUTH = "isauth";
export const LOGIN_RESPONSE = "loginResponse";
export const REGISTER_DATA = "registerData";
export const PLAN_STATUS = "statusPlan";
export const CLIENT_ID = "clientId";
export const SELECTED_LANGUAGE = "selectedLangId";
export const SYSTEM_SETTING = "systemSettingsData";
export const BUSINESS_DETAILS = "businessDetails";
export const PERSONAL_DETAILS = "personalDetails";
export const LANGUAGE_DATA = "languageData";
export const GOOGLE_API_KEY = "AIzaSyBw-hcIThiKSrWzF5Y9EzUSkfyD8T1DT4A";
export const IDBTables = {
  cropList: "crop-list",
  ledgerSummary: "ledgers-summary",
  sellerLedgers: "seller-ledgers",
  sellerLedgersTransactions: "seller-ledgers-transactions",
  buyerLedgers: "buyer-ledgers",
  buyerLedgersTransactions: "buyer-ledgers-transactions",
};
export const IndexedDbConfig: {
  db_name: string;
  version: number;
  tables: {
    name: string;
    keyPath: string;
  }[];
} = {
  db_name: "ONO-click-web",
  version: 1,
  tables: [
    {
      name: IDBTables.cropList,
      keyPath: "cropId",
    },
    {
      name: IDBTables.ledgerSummary,
      keyPath: "ledgerType",
    },
    {
      name: IDBTables.sellerLedgers,
      keyPath: "partyId",
    },
    {
      name: IDBTables.sellerLedgersTransactions,
      keyPath: "partyId",
    },
    {
      name: IDBTables.buyerLedgers,
      keyPath: "partyId",
    },
    {
      name: IDBTables.buyerLedgersTransactions,
      keyPath: "partyId",
    },
  ],
};
