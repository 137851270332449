import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAuctionBag, TAuctionLotInfo, TAuctionPartner } from "../types/smartchart/auction";
import { TBillCrop, TPartnerData } from "../types";
import { ActiveLotInfo } from "../types/smartchart/addBill";
import { OnArriavlSubLot, OnArrivalLot } from "../types/smartchart/onArrival";


type TInitialState = {
  selectedSeller: number
  crops: TBillCrop[],
  selectedBuyers: TAuctionPartner[],
  selectBuyer: TPartnerData,
  lotIndividualWeights: TAuctionBag[],
  selectedLotInfo: TAuctionLotInfo,
  isIndvidualWeightLot: boolean,
  selectedBuyer: TAuctionPartner,
  selectedBuyerIndex: number,
  isViewBags: boolean,
  refreshView: boolean,
  hideIndvidualWeights: boolean,
};
const initialState: TInitialState = {
  selectedSeller: 0,
  crops: [],
  selectedBuyers: [],
  selectBuyer: {
    isSelectedElements: false,
    trader: false,
    partyId: 0,
    profilePic: "",
    partyName: "",
    partyType: "FARMER",
    mobile: "",
    shortName: "",
  },
  selectedBuyerIndex: -1,
  lotIndividualWeights: [],
  selectedLotInfo: {
    farmerId: 0,
    farmerName: "",
    farmerAddress: "",
    profilePic: "",
    transporterId: 0,
    transporterName: "",
    lotDate: "",
    lotNumber: "",
    cropId: 0,
    cropName: "",
    cropImage: "",
    totalQty: 0,
    totalWgt: 0,
    soldQty: 0,
    soldWgt: 0,
    totalWastage: 0,
    soldWastage: 0,
    qtyUnit: "BAGS",
    rateType: "RATE_PER_KG",
    lotId: "",
    qrCode: "",
    subLotId: 0,
    mnLotId: "",
    mnSubLotId: "",
    pkgUnit: "",
    cropSufx: "",
  },
  isIndvidualWeightLot: false,
  selectedBuyer: {
    partner: {
      isSelectedElements: false,
      trader: false,
      partyId: 0,
      profilePic: "",
      partyName: "",
      partyType: "FARMER",
      mobile: "",
      address: undefined,
      shortName: ""
    },
    quantity: "",
    weight: "",
    individualWeights: [],
    wastage: "",
    netWeight: "",
    rateType: "RATE_PER_UNIT",
    sellRate: "",
    buyRate: "",
    sellTotal: "",
    buyTotal: "",
    isBuyRateChanged: false,
    qtyUnit: "BAGS",
  },
  isViewBags: false,
  refreshView: false,
  hideIndvidualWeights: false,
}
export const smartChartSlice = createSlice({
  name: "smartChartInfo",
  initialState: initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      }
    },
    setSelectedLotInfo: (state, action: PayloadAction<{
      lotInfo: OnArrivalLot,
      subLotInfo: OnArriavlSubLot,
    }>) => {
      let lotInfo = action.payload.lotInfo;
      let subLotInfo = action.payload.subLotInfo;
      state.selectedLotInfo = {
        farmerId: lotInfo.farmerId,
        farmerName: lotInfo.farmerName,
        farmerAddress: lotInfo.farmerAddress,
        profilePic: lotInfo.profilePic,
        transporterId: lotInfo.transporterId,
        transporterName: lotInfo.transporterName,
        lotDate: lotInfo.date,
        lotNumber: lotInfo.lotCode,
        cropId: subLotInfo.cropId,
        cropName: subLotInfo.cropName,
        cropImage: subLotInfo.imageUrl,
        totalQty: subLotInfo.qty,
        totalWgt: subLotInfo.weight,
        soldQty: subLotInfo.soldQty,
        soldWgt: subLotInfo.soldWeight,
        totalWastage: subLotInfo.wastage,
        soldWastage: subLotInfo.soldWastage,
        qtyUnit: subLotInfo.qtyUnit,
        rateType: subLotInfo.rateType,
        lotId: lotInfo.lotId,
        qrCode: subLotInfo.qrCode,
        subLotId: subLotInfo.subLotId,
        mnLotId: subLotInfo.mnLotId,
        mnSubLotId: subLotInfo.mnSubLotId,
        pkgUnit: subLotInfo.pkgUnit,
        cropSufx: subLotInfo.cropSufx,
      };
      let bags: TAuctionBag[] = [];
      if (subLotInfo.bags != null) {
        subLotInfo.bags
          .filter((bag) => !bag.sold)
          .forEach((bag) => {
            bags.push({
              id: bag.id,
              weight: bag.weight.toFixed(2),
              wastage: bag.wastage.toFixed(2),
              netWeight: bag.netWeight.toFixed(2),
              sellRate: "",
              buyRate: "",
              sellTotal: "",
              buyTotal: "",
              isBuyRateChanged: false,
              buyerId: 0,
              partyShortCode: '',
            })
          });
      }
      state.lotIndividualWeights = bags;
      state.isIndvidualWeightLot = bags.length != 0;
      state.selectedBuyers = [];
      state.hideIndvidualWeights = false;
      state.isViewBags = false;
    },
    setSelectedSeller: (state, action) => {
      state.selectedSeller = action.payload;
    },
    addLotCropItem: (state, action: PayloadAction<{
      crops: Array<TBillCrop>;
    }>) => {
      state.crops.push(...action.payload.crops);
    },
    setSelectedBuyers: (
      state,
      action: PayloadAction<{
        partners: TAuctionPartner[];
      }>
    ) => {
      state.selectedBuyers = action.payload.partners;
    },
    setSelectedBuyer: (
      state,
      action: PayloadAction<{
        partner: TPartnerData;
        index: number,
        buyer: TAuctionPartner,
      }>
    ) => {
      state.selectBuyer = action.payload.partner;
      state.selectedBuyerIndex = action.payload.index;
      state.selectedBuyer = action.payload.buyer;
    },
    setLotIndividualBuyers: (
      state,
      action: PayloadAction<{
        lots: TAuctionBag[]
      }>
    ) => {
      state.lotIndividualWeights = action.payload.lots;
    },
    setLotIndividualWeight: (state,
      action: PayloadAction<{
        value: boolean,
      }>
    ) => {
      state.isIndvidualWeightLot = action.payload.value;
    },
    setViewIndividualWeights: (state, action: PayloadAction<{
      value: boolean,
    }>) => {
      state.isViewBags = action.payload.value;
    },
    setRefreshSmartChartView: (state) => {
      state.refreshView = !state.refreshView;
    },
    setHideIndividualWeights: (state) => {
      state.hideIndvidualWeights = true;
    }
  },
});

export const {
  setSelectedSeller,
  addLotCropItem,
  setSelectedBuyers,
  setSelectedBuyer,
  setLotIndividualWeight,
  setSelectedLotInfo,
  setViewIndividualWeights,
  setLotIndividualBuyers,
  setRefreshSmartChartView,
  setHideIndividualWeights,
} = smartChartSlice.actions;

export default smartChartSlice.reducer;
