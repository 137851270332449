import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetLots, SalesSummaryInfo } from "../types/smartchart/salesSummary";


type TInitialState = {
    lots: GetLots[],
    selectedLot: GetLots,
    salesSummary: SalesSummaryInfo | null,
    refreshSalesSummary: boolean,
}

const initialState: TInitialState = {
    lots: [],
    selectedLot: {
        lotId: 0,
        partyName: "",
        date: "",
        lotCode: "",
        addressLine: "",
    },
    salesSummary: {
        fname: "",
        fshortCode: "",
        addressLine: "",
        lotDate: "",
        profilePic: "",
        purchaseAmt: 0,
        totalSaleAmt: 0,
        totalMrgn: 0,
        grossMargin: 0,
        comm: 0,
        soldQty: 0,
        soldWgt: 0,
        wastage: 0,
        qwstge: 0,
        wwstge: 0,
        tlQty: 0,
        tlWgt: 0,
        unSoldQty: 0,
        unSoldWeight: 0,
        crops: [],
    },
    refreshSalesSummary: false,
}

export const salesSummarySlice = createSlice({
    name: "salesSummary",
    initialState: initialState,
    reducers: {
        resetSalesSummary: (state) => {
            return {
                ...initialState,
            }
        },
        setLots: (state, action) => {
            state.lots = action.payload;
        },
        setSelectedLot: (state, action) => {
            state.selectedLot = action.payload;
        },
        setSalesSummary: (state, action: PayloadAction<{
            salesSummary: SalesSummaryInfo | null,
        }>) => {
            state.salesSummary = action.payload.salesSummary ?? null;
        },
        setRefreshSalesSummary: (state) => {
            state.refreshSalesSummary = !state.refreshSalesSummary;
        }
    }
});
export const {
    setLots,
    setSelectedLot,
    setSalesSummary,
    setRefreshSalesSummary,
    resetSalesSummary,
} = salesSummarySlice.actions;

export default salesSummarySlice.reducer;
